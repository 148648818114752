var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('div',{attrs:{"id":"air-gap"}},[_c('h3',{staticClass:"mb-20"},[_vm._v("\n    "+_vm._s(_vm.t('harvester.upgradePage.osUpgrade'))+"\n  ")]),_vm._v(" "),_c('CruResource',{attrs:{"done-route":_vm.doneRoute,"resource":_vm.value,"mode":"create","errors":_vm.errors,"can-yaml":false,"finish-button-mode":"upgrade","cancel-event":true},on:{"finish":_vm.save,"cancel":_vm.done}},[_c('RadioGroup',{staticClass:"image-group",attrs:{"name":"image","options":[
        _vm.IMAGE_METHOD.NEW,
        _vm.IMAGE_METHOD.EXIST,
      ],"labels":[
        _vm.t('harvester.upgradePage.uploadNew'),
        _vm.t('harvester.upgradePage.selectExisting'),
      ]},model:{value:(_vm.imageSource),callback:function ($$v) {_vm.imageSource=$$v},expression:"imageSource"}}),_vm._v(" "),_c('UpgradeInfo'),_vm._v(" "),(_vm.uploadImage)?_c('div',[_c('LabeledInput',{staticClass:"mb-20",attrs:{"label-key":"harvester.fields.name","required":""},model:{value:(_vm.imageValue.spec.displayName),callback:function ($$v) {_vm.$set(_vm.imageValue.spec, "displayName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"imageValue.spec.displayName"}}),_vm._v(" "),_c('LabeledInput',{staticClass:"mb-10",attrs:{"label-key":"harvester.setting.upgrade.checksum"},model:{value:(_vm.imageValue.spec.checksum),callback:function ($$v) {_vm.$set(_vm.imageValue.spec, "checksum", $$v)},expression:"imageValue.spec.checksum"}}),_vm._v(" "),(_vm.canEnableLogging)?_c('Checkbox',{staticClass:"check mb-20",attrs:{"type":"checkbox","label":_vm.t('harvester.upgradePage.enableLogging')},model:{value:(_vm.enableLogging),callback:function ($$v) {_vm.enableLogging=$$v},expression:"enableLogging"}}):_vm._e(),_vm._v(" "),_c('RadioGroup',{staticClass:"mb-20 image-group",attrs:{"name":"sourceType","options":[
          'upload',
          'download',
        ],"labels":[
          _vm.t('harvester.image.sourceType.upload'),
          _vm.t('harvester.image.sourceType.download')
        ]},model:{value:(_vm.sourceType),callback:function ($$v) {_vm.sourceType=$$v},expression:"sourceType"}}),_vm._v(" "),(_vm.sourceType === 'download')?_c('LabeledInput',{staticClass:"labeled-input--tooltip",attrs:{"required":"","label-key":"harvester.image.url"},model:{value:(_vm.imageValue.spec.url),callback:function ($$v) {_vm.$set(_vm.imageValue.spec, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"imageValue.spec.url"}}):_c('div',{staticClass:"chooseFile"},[_c('button',{staticClass:"btn role-primary",attrs:{"type":"button"},on:{"click":_vm.selectFile}},[_vm._v("\n          "+_vm._s(_vm.t('harvester.image.uploadFile'))+"\n          "),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"file",attrs:{"id":"file","type":"file","accept":".iso"},on:{"change":function($event){return _vm.handleFileUpload()}}})]),_vm._v(" "),_c('span',{staticClass:"ml-20",class:{ 'text-muted': !_vm.fileName }},[_vm._v("\n          "+_vm._s(_vm.fileName ? _vm.fileName : _vm.t('harvester.generic.noFileChosen'))+"\n        ")])])],1):_c('LabeledSelect',{staticClass:"mb-20",attrs:{"options":_vm.osImageOptions,"required":"","label-key":"harvester.fields.image"},model:{value:(_vm.imageId),callback:function ($$v) {_vm.imageId=$$v},expression:"imageId"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }