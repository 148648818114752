var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$fetchState.pending)?_c('Loading'):_c('CruResource',{attrs:{"resource":_vm.value,"mode":_vm.mode,"errors":_vm.errors},on:{"finish":_vm.save}},[_c('NameNsDescription',{attrs:{"value":_vm.value,"mode":_vm.mode,"namespaced":false}}),_vm._v(" "),_c('Tabbed',{attrs:{"side-tabs":true}},[_c('Tab',{attrs:{"name":"nodeSelector","label":_vm.t('harvester.vlanConfig.titles.nodeSelector'),"weight":99}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col span-12"},[_c('Banner',{attrs:{"color":(_vm.matchingNodes.none ? 'warning' : 'success')}},[_c('span',{directives:[{name:"clean-html",rawName:"v-clean-html",value:(_vm.t('harvester.vlanConfig.nodeSelector.matchingNodes.matchesSome', _vm.matchingNodes)),expression:"t('harvester.vlanConfig.nodeSelector.matchingNodes.matchesSome', matchingNodes)"}]})])],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col span-12"},[_c('NodeSelector',{attrs:{"mode":_vm.mode,"value":_vm.value.spec,"nodes":_vm.nodeOptions},on:{"updateMatchingNICs":_vm.updateMatchingNICs,"updateMatchingNodes":_vm.updateMatchingNodes}})],1)])]),_vm._v(" "),_c('Tab',{attrs:{"name":"upLink","label":_vm.t('harvester.vlanConfig.titles.uplink'),"weight":99,"show-header":false}},[_c('div',{staticClass:"row mt-10"},[_c('div',{staticClass:"col span-6"},[_c('LabelValue',{attrs:{"name":_vm.t('harvester.network.clusterNetwork.label'),"value":_vm.value.spec.clusterNetwork}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabelValue',{attrs:{"name":"Type","value":"VLAN"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-20"},[_c('div',{staticClass:"col span-12"},[_vm._l((_vm.nicErrors),function(err,i){return _c('Banner',{key:i,attrs:{"color":"warning","label":err}})}),_vm._v(" "),_c('ArrayListSelect',{attrs:{"mode":_vm.mode,"options":_vm.nicOptions,"array-list-props":{
              addLabel: _vm.t('harvester.vlanConfig.uplink.nics.addLabel'),
              initialEmptyRow: true,
              title: _vm.t('harvester.vlanConfig.uplink.nics.label'),
              required: true,
              protip: false,
            },"select-props":{
              placeholder: _vm.t('harvester.vlanConfig.uplink.nics.placeholder'),
            }},model:{value:(_vm.value.spec.uplink.nics),callback:function ($$v) {_vm.$set(_vm.value.spec.uplink, "nics", $$v)},expression:"value.spec.uplink.nics"}})],2)]),_vm._v(" "),_c('h3',{staticClass:"mt-20"},[_vm._v("\n        "+_vm._s(_vm.t('harvester.vlanConfig.titles.bondOptions'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledSelect',{attrs:{"label":_vm.t('harvester.vlanConfig.uplink.bondOptions.mode.label'),"mode":_vm.mode,"options":_vm.bondOptions,"required":""},model:{value:(_vm.bondOptionMode),callback:function ($$v) {_vm.bondOptionMode=$$v},expression:"bondOptionMode"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"label":_vm.t('harvester.vlanConfig.uplink.bondOptions.miimon.label'),"mode":_vm.mode,"type":"number","tooltip":_vm.t('harvester.vlanConfig.uplink.bondOptions.miimon.tooltip', null, true)},model:{value:(_vm.miimon),callback:function ($$v) {_vm.miimon=_vm._n($$v)},expression:"miimon"}})],1)]),_vm._v(" "),_c('h3',{staticClass:"mt-20"},[_vm._v("\n        "+_vm._s(_vm.t('harvester.vlanConfig.titles.attributes'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"row mt-10"},[_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"label":_vm.t('harvester.vlanConfig.uplink.linkAttributes.mtu.label'),"mode":_vm.mode,"type":"number"},model:{value:(_vm.mtu),callback:function ($$v) {_vm.mtu=_vm._n($$v)},expression:"mtu"}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }