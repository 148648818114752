<script>
import ResourceDetail from '@shell/components/ResourceDetail/index.vue';

export default {
  name:       'HarvesterResourceCreate',
  components: { ResourceDetail },
};
</script>

<template>
  <ResourceDetail />
</template>
