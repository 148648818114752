var render, staticRenderFns
import script from "./InputOrDisplay.vue?vue&type=script&lang=js&"
export * from "./InputOrDisplay.vue?vue&type=script&lang=js&"
import style0 from "./InputOrDisplay.vue?vue&type=style&index=0&id=16d50992&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d50992",
  null
  
)

export default component.exports